import React from 'react';
import {Card, CardImg, CardBody, CardTitle} from 'reactstrap';
import './galeriaClass.css';

export class GaleriaClass extends React.Component {
    constructor(props) {
        super (props)
    }
    render() {
        return(
            <div className="col-md-3">
                <Card className="galleryCard">
                     <CardImg className="galleryCards" top width="100%" src={this.props.picture.formats.medium.url} alt="Card image cap" />
                </Card>

                
               {/* <Card className="galleryCard">
                    <CardImg className="cardImage" top width="100%" src={this.props.picture.formats.medium.url} alt="Card image cap" />
                    <CardBody>
                    <CardTitle className="linespacing">{this.props.name}</CardTitle>
                    </CardBody>
                </Card>                 */}
            </div>
        )
    }
}
export default GaleriaClass;