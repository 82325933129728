import React from "react";
import "./galeriaListing.css";
import GaleriaClass from "./../galeriaClass/galeriaClass";

export class GaleriaListing extends React.Component {
    constructor() {
        super();
        this.state = {
            galleryPictures: [],
        };
    }

    componentDidMount() {

    fetch('https://vegabontycmsone.azurewebsites.net/gallery-pictures')
    .then(response => response.json())
    .then(data => {
        let galleryPictures = data.map((galleryPicture) => {
            return (
                <GaleriaClass name={galleryPicture.Name} picture={galleryPicture.Picture}/>
            )
        })
        this.setState({galleryPictures: galleryPictures});
    })
}
    render() {
        return(
            <div className="container">
                <div className="homeCategoryListing">
                      <div className="row "> 
                         {this.state.galleryPictures}
                     </div>
                </div>
            </div>
        )
    }
}                     
export default GaleriaListing;

