import React from "react";
import "./productListing.css";
import ProductListingClass from "../productListingClass/productListingClass";

export class ProductListing extends React.Component {
  constructor(props) {
    super (props);
        this.state = {
          products: [],
          };
  }
componentDidMount() {
  
  let searchURL = 'https://vegabontycmsone.azurewebsites.net/products?category.id=' + this.props.match.params.categoryId;
  console.log(this.props.match.params.categoryId);
  if (this.props.match.params.categoryName==="buscar") {
    searchURL = 'https://vegabontycmsone.azurewebsites.net/products?_where[_or][0][Name_contains]=' + this.props.match.params.categoryId +  '&_where[_or][1][category.Title_contains]=' + this.props.match.params.categoryId;
  }
  
  fetch(searchURL)
  .then(response => response.json())
  .then(data => {
      let products = data.map((product) => {
          return (
              <ProductListingClass key={product.id} id={product.id} name={product.Name} description={product.Description} image={product.Image} allergies={product.Allergies}/>
          )
      })
      this.setState({products: products});
  })
}
render() {
  return(
    <div className="container">
        <div className="homeCategoryListing">                
          <div className="row "> 
            {this.state.products}
        </div>
      </div>
    </div>
)
    }
};

export default ProductListing;