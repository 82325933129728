import React from "react";
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import "./contacto.css";

export class Contacto extends React.Component {

    constructor() {
        super();
        this.state = {
            pagetitle: ""
        }
    }
    componentDidMount() {

        // Hämta fras
        fetch('https://vegabontycmsone.azurewebsites.net/phrases/1')
        .then(response => response.json())
        .then(phrase => {this.setState({pagetitle: phrase.Content});
        })
        
    }
    render() {
        return(
            <div className="container">
                {/* <Link to="/contacto">Contacto</Link> */}
                <p className="contacto">Ponte en contacto con nosotros!</p>
                <Form className="contact-form">
                    <FormGroup>
                            <Label for="nombre" className="list">Nombre</Label>
                            <Input className="inputContact" type="nombre" name="nombre" id="examplNombre"/>
                    </FormGroup>
                    <FormGroup>
                            <Label for="correo" className="list">Correo electrónico</Label>
                            <Input className="inputContact" type="correo" name="correo" id="exampleCorreo"/>
                    </FormGroup>                    
                    <FormGroup>
                            <Label for="exampleText" className="list">Mensaje</Label>
                            <Input className="inputContact" type="textarea" name="text" id="exampleText" />
                    </FormGroup>
                    <FormGroup>
                            <Input type="checkbox"/><br/>
                            Inscríbete para nuestra lista de correo electrónico para recibir actualizaciones, promociones y más.
                            <br/>
                    </FormGroup>
                    <Button className="enviar btn">Enviar</Button>
                </Form>

                <h1 className="phrase1">{this.state.pagetitle}</h1> 
                <Form className="contact-form">
                     <p className="suscribirseText">Suscríbete para recibir noticias sobre promociones, ofertas y eventos.</p>
                        <FormGroup>
                            <Label for="nombre" className="list"></Label>
                            <Input className="inputContact" placeholder="e-mail" type="nombre" name="nombre" id="examplNombre"/>
                        </FormGroup>
                <Button className="subscribir btn">Enviar</Button>              
                </Form>
              
               
            </div>
        );
    };
}

export default Contacto;