import React from "react";
import Category from '../category/category';

export class CategoryListing extends React.Component {
    constructor() {
        super();
            this.state = {
                categories: [],
            };
    }
componentDidMount() {

    // Hämta kategorirer
    fetch('https://vegabontycmsone.azurewebsites.net/categories')
    .then(response => response.json())
    .then(data => {
        let categories = data.map((category) => {
            return (
                <Category key={category.id} id={category.id} title={category.Title} description={category.Description} image={category.Image}/>
            )
        })
        // Tilldela arrayn till statevariablnm categories
        console.log(categories);
        this.setState({categories: categories});
    })
}    
    render() {
        return(
            <div className="row"> 
                {this.state.categories}
            </div>
        )
    }
}
export default CategoryListing;