import React from 'react';
import './productClass.css';
import { Button } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';

export class ProductClass extends React.Component {
    constructor(props) {
        super(props);
        
        this.replaceMe = this.replaceMe.bind(this);        

        this.state = {
            tmpImage: "",
            price1Amount: 1,
            price2Amount: 1,
            price3Amount: 1
        }
    }

    componentDidMount() {

        this.setState({tmpImage: '/static/media/macaron_NY.4a8ab345.jpg'})
        if (this.props.image[0].formats!==null)
        {
            if (this.props.image[0].formats.medium!==null && this.props.image[0].formats.medium!==undefined)
            {
                this.setState({tmpImage: this.props.image[0].formats.medium.url})
            }  
        }
        
    }

    addCartItem(input,sizeValue,amount) {
        this.props.addCartItem(input,sizeValue,amount);  
        console.log(amount);  
        toast(amount + ' cosita/s añadida a tu carrito!');        
    }

    replaceMe(inText) {
        console.log("Checking: " + inText)
        if (inText!== null && inText !== undefined) {
            inText = inText.replace(/<[^>]+>/g, '');
            inText = inText.replace('&nbsp;','');
        }
        return inText;
    }

    render() {
        const price1Btn = parseFloat(parseFloat(this.props.productData.Price1).toFixed(2)) > parseFloat(parseFloat(0).toFixed(2)) ? <div className="">
            <span className="info-tarta">{this.props.productData.Price1Text}{!this.props.productData.Price1Text ? "" : " á "}{this.props.productData.Price1}€
            &nbsp;&nbsp;
            <div className="btn" onClick={()=>this.setState({price1Amount:this.state.price1Amount>1 ? (this.state.price1Amount-1) : 1})}>-</div>
            &nbsp;&nbsp;
            {this.state.price1Amount}
            &nbsp;&nbsp;
            <div className="btn" onClick={()=>this.setState({price1Amount:this.state.price1Amount+1})}>+</div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <Button onClick={()=>this.addCartItem(this.props.id,1,this.state.price1Amount)}>Añadir</Button></div> : ""    

        const price2Btn = parseFloat(parseFloat(this.props.productData.Price2).toFixed(2)) > parseFloat(parseFloat(0).toFixed(2)) ? <div className="">
            <span className="info-tarta">{this.props.productData.Price2Text}{!this.props.productData.Price2Text ? "" : " á "}{this.props.productData.Price2}€
            &nbsp;&nbsp;
            <div className="btn" onClick={()=>this.setState({price2Amount:this.state.price2Amount>1 ? (this.state.price2Amount-1) : 1})}>-</div>
            &nbsp;&nbsp;
            {this.state.price2Amount}
            &nbsp;&nbsp;
            <div className="btn" onClick={()=>this.setState({price2Amount:this.state.price2Amount+1})}>+</div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <Button onClick={()=>this.addCartItem(this.props.id,2,this.state.price2Amount)}>Añadir</Button></div> : ""    

        const price3Btn = parseFloat(parseFloat(this.props.productData.Price3).toFixed(2)) > parseFloat(parseFloat(0).toFixed(2)) ? <div className="">
            <span className="info-tarta">{this.props.productData.Price3Text}{!this.props.productData.Price3Text ? "" : " á "}{this.props.productData.Price3}€
            &nbsp;&nbsp;
            <div className="btn" onClick={()=>this.setState({price3Amount:this.state.price3Amount>1 ? (this.state.price3Amount-1) : 1})}>-</div>
            &nbsp;&nbsp;
            {this.state.price3Amount}
            &nbsp;&nbsp;
            <div className="btn" onClick={()=>this.setState({price3Amount:this.state.price3Amount+1})}>+</div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <Button onClick={()=>this.addCartItem(this.props.id,3,this.state.price3Amount)}>Añadir</Button></div> : ""     

        let priceText = "";
        if (!parseFloat(parseFloat(this.props.productData.Price1).toFixed(2)) > parseFloat(parseFloat(0).toFixed(2)) &&
                        !parseFloat(parseFloat(this.props.productData.Price2).toFixed(2)) > parseFloat(parseFloat(0).toFixed(2)) &&
                        !parseFloat(parseFloat(this.props.productData.Price3).toFixed(2)) > parseFloat(parseFloat(0).toFixed(2))) {
            priceText = <span className="button-info">No hay tamaños disponibles ahora</span>
        }
        
        let priceChoiceText = "Elige tu tamaño";
        if (price1Btn!=="" && price2Btn==="" && price3Btn==="") {
                            priceChoiceText = "";
                            console.log("bla")
        }

        return(
            <div className="container">
                <Toaster />
                <div className="row">
                    <div className="col-md-5">
                        <img className="image" width="100%" src={this.state.tmpImage} alt="Producto" />
                    </div>                                 
                    <div className="col-md-7">
                        <div className="col-md-12 name">{this.props.name}</div> 
                        <div className="information">{this.replaceMe(this.props.description)} {this.replaceMe(this.props.other)} <br/><br/>                        
                            Alérgenos: {this.replaceMe(this.props.allergies)} <br></br>
                            <br></br>
                        </div>
                        <p className="tamaño"> {priceChoiceText}</p>
                        <div className="tamaño-group">                            
                            {price1Btn}           
                            {price2Btn}   
                            {price3Btn}
                            {priceText}   
                        </div>
                    </div>
                </div>
                <br/><br/>   
                <div className="row">
                    <div className="col-md-12">
                        *Si desea pedir más de 8 tartas, contáctenos directamente!
                    </div>  
                </div>  
                <br/><br/>  
            </div>


       )
    }
}

export default ProductClass;