import React from 'react';
import { Link } from 'react-router-dom';
import {Card, CardImg, CardBody, CardTitle} from 'reactstrap';
import './category.css';

export class Category extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            tmpImage: ""
        }
    }

    componentDidMount() {
        
        this.setState({tmpImage: '/static/media/macaron_NY.4a8ab345.jpg'})
        if (this.props.image[0].formats!==null)
        {
            if (this.props.image[0].formats.medium!==null && this.props.image[0].formats.medium!==undefined)
            {
                this.setState({tmpImage: this.props.image[0].formats.medium.url})
            }  
        }
        
    }     

    render() {

        return(
            <div className="col-md-3">
               <Card className="cardframe_frontpage">
               <Link to={"nuestrosdulces/"+this.props.title + "/" + this.props.id} style={{ textDecoration: 'none'}}>
                    <CardImg className="cardImage" top width="100%" src={this.state.tmpImage} alt="Card image cap" />
                    <CardBody>
                    <CardTitle className="linespacing">{this.props.title}</CardTitle>
                    {this.props.description}
                    </CardBody></Link>
                </Card>                
            </div>

        );
    };
}
                    

export default Category;