import React from "react";
import "./product.css";
import ProductClass from "../productClass/productClass";

export class Product extends React.Component {
  constructor(props) {
    super (props);
        this.state = {
          products: [],
          };
  }

componentDidMount() {
  fetch('https://vegabontycmsone.azurewebsites.net/products/'+ this.props.match.params.productId)
  .then(response => response.json())
  .then(product => {
      let products = <ProductClass productData={product} addCartItem={this.props.addCartItem} id={product.id} name={product.Name} description={product.Description} image={product.Image} allergies={product.Allergies } price={product.Price} other={product.Other}/>    
      
      this.setState({products: products});
  })
}
render() {
  return(
    <div className="row">
            {this.state.products} 
    </div>
)
    }
};

export default Product;