import React from "react";
import "./checkout.css";
import  CheckoutClass from '../checkoutClass/checkoutClass';
import { Link } from 'react-router-dom';
import { Col, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {getCMSPhrase} from "./../CMSHelper/CMSHelper";
import toast, { Toaster } from 'react-hot-toast';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export class Checkout extends React.Component {
  constructor(props) {
    super (props);

    this.updateTotalAmount = this.updateTotalAmount.bind(this);
    this.changeProvince = this.changeProvince.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.showModal = this.showModal.bind(this);
    this.sabadellAPI = this.sabadellAPI.bind(this);
    this.prepSabadellForm = this.prepSabadellForm.bind(this);   
    this.createOrder = this.createOrder.bind(this);     
    this.updateFreightMode = this.updateFreightMode.bind(this);    
    this.updateFreightPrice = this.updateFreightPrice.bind(this);  
    this.handleChange = this.handleChange.bind(this); 
    this.approvedConditionsUpdate = this.approvedConditionsUpdate.bind(this);
    this.validateAddresses = this.validateAddresses.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.renderRawCartRow = this.renderRawCartRow.bind(this);    
    
    this.state = {
          products: [],
          productsRaw: [],
          totalAmount: 0,
          totalPrice: 0,
          freightPrice: 0,
          freightMode: 0,
          provinceValue: "",
          cityValue: "",
          localPrice: 5,
          provincePrice: 10,
          nationPrice: 9,
          modal: false,
          legalPhrase: "",
          strPayment64: "",
          finalStringValue: null,
          createResponse: "",
          orderId: "",
          minOrderDate: new Date(),
          approvedConditions: false,
          absoluteTotalPrice: 0
        };
  }

  approvedConditionsUpdate () {
    this.setState({approvedConditions: !this.state.approvedConditions});
  }

  handleChange(value) {
      this.setState({
        value: value
      });
  }
 
  componentDidUpdate() {
    // Access ISO String and formatted values from the DOM.
    var hiddenInputElement = document.getElementById("recogerfecha");
  }

  async componentDidMount() {
    window.provinceFunction();
    this.populateCart();

    this.setState({legalPhrase: getCMSPhrase(4)})
    
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var RandomNumber = Math.floor(Math.random() * 100) + 1 ;

    var result = new Date();
    result.setDate(result.getDate() + 4);
    var someResult = new Date();
    someResult.setDate(someResult.getDate() + 4);

    this.setState({minOrderDate: someResult, value: result});

    this.setState({orderId: "VB" + year + month + date + RandomNumber});
      if(!window.location.hash) {
          window.location = window.location + '#quien';
          window.location.reload();
      }
  }  
  
async retrieveCartData() { 
  const data = Promise.all(
    this.props.cartData.filter(num => num.amount > 0).map(async (i) => await (await fetch(`https://vegabontycmsone.azurewebsites.net/products/${i.id}`, {
          method: "GET",
          headers: {
              "Content-Type": "application/json"
          }
      })).json())
  )
  
  return data
};  

async renderRawCartRow() {      
  var retrievedCartData = await this.retrieveCartData();
  
  if (retrievedCartData!=null) {
    return this.props.cartData.filter(num => num.amount > 0).map((currentRow) => {
      return this.workWithMeRaw(currentRow,retrievedCartData);
    });
  }
}

async renderCartRow() {      
    var retrievedCartData = await this.retrieveCartData();
    
    if (retrievedCartData!=null) {
      return this.props.cartData.filter(num => num.amount > 0).map((currentRow) => {
        return this.workWithMe(currentRow,retrievedCartData);
      });
    }
}

workWithMe (currentRow, productDataArray) {

  if (!productDataArray) { return false; }

  const productData = productDataArray.find((element) => {
    return element.id === currentRow.id;
  })

  if (currentRow.size===1) {this.updateTotalPrice(productData.Price1*currentRow.amount)} 
  if (currentRow.size===2) {this.updateTotalPrice(productData.Price2*currentRow.amount)}
  if (currentRow.size===3) {this.updateTotalPrice(productData.Price3*currentRow.amount)}

  let finalPrizeText = "";
  let finalPrize = 0;

  if (currentRow.size===1) {
    finalPrizeText = productData.Price1Text ? "(" + productData.Price1Text + ")" : "";
    finalPrize = productData.Price1;
  }
  if (currentRow.size===2) {
    finalPrizeText = productData.Price2Text ? "(" + productData.Price2Text + ")" : "";
    finalPrize = productData.Price2;
  }
  if (currentRow.size===3) {
    finalPrizeText = productData.Price3Text ? "(" + productData.Price3Text + ")" : "";
    finalPrize = productData.Price3;
  }

  this.updateTotalPrice(finalPrize*currentRow.amount);

  const rowStyle = "row"; //amount > 0 ? "row" : "hiddenRow";

  return (<div className={rowStyle} key={productData.id + "_" + currentRow.size}>
    <div className="col-9 cartName"><Link to={"/producto/" + productData.id} style={{ textDecoration: 'none'}}>{productData.Name + " " + (finalPrizeText === null ? "" : finalPrizeText + " X " + currentRow.amount)}</Link></div>
    <div className="col-3 checkoutCosts">{Number(finalPrize*currentRow.amount).toFixed(2)}€</div>
  </div>);
}

workWithMeRaw (currentRow, productDataArray) {

  if (!productDataArray) { return false; }

  const productData = productDataArray.find((element) => {
    return element.id === currentRow.id;
  })

  let finalPrizeText = "";
  let finalPrize = 0;
  let finalPrizeSubText = 0;

  if (currentRow.size===1) {
    finalPrizeText = productData.Price1Text ? productData.Price1Text : "";
    finalPrize = productData.Price1;
    finalPrizeSubText = productData.Price1Text;
  }
  if (currentRow.size===2) {
    finalPrizeText = productData.Price2Text ? productData.Price2Text : "";
    finalPrize = productData.Price2;
    finalPrizeSubText = productData.Price2Text;
  }
  if (currentRow.size===3) {
    finalPrizeText = productData.Price3Text ? productData.Price3Text : "";
    finalPrize = productData.Price3;
    finalPrizeSubText = productData.Price3Text;
  }

  return ({productName: productData.Name, productPrice: finalPrize, productAmount: currentRow.amount, productTotal: Number(finalPrize*currentRow.amount).toFixed(2), productId: currentRow.id, productSize: finalPrizeText, productSizeCode: currentRow.size})
}

async updateTotalPrice (totalPrice) {
  this.setState({totalPrice: (this.state.totalPrice+totalPrice)});
  if (Number(this.state.totalPrice/2)>=100) {
    await this.setState({freightPrice: 0});
  }
  const newPrice = Number(this.state.totalPrice/2);
  await this.setState({absoluteTotalPrice:newPrice});
}

async populateCart() {   
  this.updateTotalAmount();
  this.setState({totalPrice: 0});
  this.setState({products: await this.renderCartRow()});
  await this.setState({productsRaw: await this.renderRawCartRow()});    
  console.log(this.state.productsRaw)
}

  async prepSabadellForm() { 
    this.approvedConditionsUpdate();
    this.sabadellAPI(this.state.absoluteTotalPrice, this.state.orderId);
  }
  
  async sabadellAPI(orderAmount, orderId) {

    let newSum = Number(orderAmount.toString());
    if (newSum % 1 === 0) {
      newSum = Number(orderAmount.toString().replace(".","")) * 100
    } else {
      newSum = Number(orderAmount)*100;
    }

    await fetch('https://vegabonty.akiredo.com/sabadell.php?ordernumber=' + orderId + '&orderamount=' + newSum)
        .then(response => response.json())
        .then(response => {
          this.setState({finalStringValue: response});})
        .catch(err => console.error(err));
  }

  async updateFreightPrice(freightMode) {
    if (freightMode===1) {
      if (Number(this.state.totalPrice/2)>=100) {
        await this.setState({freightPrice: 0});
      } else {
        await this.setState({freightPrice: this.state.nationPrice});
      }
    }
    else {
      await this.setState({freightPrice: 0});
    }
  }

  updateFreightMode(freightMode) {
    this.setState({freightMode:freightMode});
    this.updateFreightPrice(freightMode);
  }
  
  updateTotalAmount() {
    this.setState({totalAmount: this.props.cartData.filter(num => num.amount > 0).reduce((total, currentValue) => total = total + currentValue.amount,0)});    
  }

  changeProvince(evt) {
    var index = evt.target.selectedIndex;
    this.setState({provinceValue: evt.target[index].text});
  }

  changeCity(evt) {
    var index = evt.target.selectedIndex;
    this.setState({cityValue: evt.target[index].text});
    this.updateFreightPrice();
  }

  showModal() {
    this.setState({modal: !this.state.modal});
  }

  getProductsHtml() {
    if (this.state.finalStringValue === null || this.state.approvedConditions === false || this.state.value === undefined) {
      return <input type="button" onClick={()=>alert("Tienes que aceptar nuestras politicas de venta")} className="checkout" value="Finalizar - Aceptar politicas de venta y elegir fecha" />;
    } else {
      return  <form name="from" id="from" method="POST" action="https://sis-t.redsys.es:25443/sis/realizarPago">
                  <input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>
                  <input type="hidden" name="Ds_MerchantParameters" value={this.state.finalStringValue[0]} />
                  <input type="hidden" name="Ds_Signature" value={this.state.finalStringValue[1]}/>
                  <input type="button" onClick={this.createOrder} className="checkout" value="Seguir al Pago" />
              </form>
    } 
  }

  validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  validateAddresses() {
    const firstName = document.getElementById('firstName').value;
    const lastName = document.getElementById('lastName').value;
    const email = document.getElementById('email').value;
    const addressLine1 = document.getElementById('addressLine1').value;
    const zip = document.getElementById('zip').value;
    const province = document.getElementById('province').value;
    const city = document.getElementById('city').value;

    let errorSummary = "";
    if (!firstName || !firstName.length > 0)
    {
      errorSummary = errorSummary + "Falta Nombre\n";
    }
    if (!lastName || !lastName.length > 0)
    {
      errorSummary = errorSummary + "Falta Apellidos\n";
    }
    if (!email || !email.length > 0 || this.validateEmail(email) === false)
    {
      errorSummary = errorSummary + "Falta E-mail valido\n";
    }
    if (!addressLine1 || !addressLine1.length > 0)
    {
      errorSummary = errorSummary + "Falta Calle y numero\n";
    }
    if (!province || !province.length > 0 || province === "Provincia")
    {
      errorSummary = errorSummary + "Falta Provincia\n";
    }
    if (!city || !city.length > 0)
    {
      errorSummary = errorSummary + "Falta Municipio\n";
    }
    if (!zip || !zip.length > 0)
    {
      errorSummary = errorSummary + "Falta Codigo Postal\n";
    }

    return errorSummary;
  }

  async createOrder () {   

    const validationText = this.validateAddresses();
    if (validationText!="") {
      alert("Habia errores con vuestro pedido;\n\n" + validationText);
      return false;
    }
    else {
      const firstName = document.getElementById('firstName').value;
      const lastName = document.getElementById('lastName').value;
      const email = document.getElementById('email').value;
      const phone = document.getElementById('phone').value;
      const addressLine1 = document.getElementById('addressLine1').value;
      const addressLine2 = document.getElementById('addressLine2').value;
      const province = document.getElementById("province").options[document.getElementById("province").selectedIndex].text;
      const city = document.getElementById("city").options[document.getElementById("city").selectedIndex].text;
      const zip = document.getElementById('zip').value;
      const message = document.getElementById('message').value;
      const totalCost = this.state.totalPrice;
      const transportCost = this.state.freightPrice;
      
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ 
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          province: province,
          city: city,
          zip: zip,
          message: message,
          totalCost: (totalCost/2),
          transportCost: transportCost,
          freightMode: this.state.freightMode,
          orderId: this.state.orderId,
          pickupDate: this.state.value,
          cartData: this.props.cartData,
          cartDataRaw: this.state.productsRaw
        })};

      await fetch('https://vegabonty.akiredo.com/storeOrder.php', requestOptions)
        .then(response => response.json())
        .then(data => {})

      await fetch('https://vegabonty.akiredo.com/vegabontyMailer.php?orderId=' + this.state.orderId)
        .then(response => response.json())
        .then(response => {console.log(response)})
        .catch(err => console.error(err));

      await this.props.flushCart();
      
      document.getElementById("from").submit();
    }
  }

  render() {

    let formCode ="";
    let datePicker = this.state.minOrderDate ? <DatePicker 
                                                id="recogerfecha" 
                                                dateFormat = "yyyy-MM-dd" 
                                                minDate={this.state.minOrderDate}
                                                selected={this.state.value}
                                                onChange={(value) => this.handleChange(value)} /> : "";
    
    if (this.state.finalStringValue === null) {
      formCode = this.getProductsHtml();
    } else {
      formCode = this.getProductsHtml();
    } 
    
  return(
    <div className="container"> 
      <Toaster />
      <p className="contacto">Enviar mi pedido</p>     
      
      <Form className="checkoutForm">
          <FormGroup>
            <p className="contacto-other" id="quien">Quien eres?</p> 
          </FormGroup>
          <FormGroup row>
            <Label for="firstName" sm={2}>Nombre*</Label>
            <Col sm={4}>
              <Input type="text" name="firstName" id="firstName" placeholder="Maria" />
            </Col>
            <Label for="lastName" sm={2}>Apellidos*</Label>
            <Col sm={4}>
              <Input type="lastName" name="lastName" id="lastName" placeholder="Gonzales" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="email" sm={2}>E-mail*</Label>
            <Col sm={4}>
              <Input type="email" name="email" id="email" placeholder="maria@vegabonty.com" />
            </Col>
            <Label for="phone" sm={2}>Teléfono</Label>
            <Col sm={4}>
              <Input type="phone" name="phone" id="phone" placeholder="616123456" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="addressLine1" sm={2}>Calle y numero*</Label>
            <Col sm={4}>
              <Input type="addressLine1" name="addressLine1" id="addressLine1" placeholder="Calle Maria 1B" />
            </Col>
            <Label for="zip" sm={2}>Codigo Postal*</Label>
            <Col sm={4}>
              <Input type="zip" name="zip" id="zip" placeholder="29649" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="addressLine2" sm={2}>Extra Calle información</Label>
            <Col sm={10}>
              <Input type="addressLine2" name="addressLine2" id="addressLine2" placeholder="Calle Extra" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="province" sm={2}>Provincia*</Label>
            <Col sm={4}>
              <Input type="select" name="province" id="province" onChange={this.changeProvince} ></Input>
            </Col>
            <Label for="city" sm={2}>Población*</Label>
            <Col sm={4}>
              <Input type="select" name="city" id="city" onChange={this.changeCity} ></Input>
            </Col>
          </FormGroup>
          <FormGroup>
            <p className="contacto-other" id="quien">Envio</p> 
          </FormGroup>
          <FormGroup tag="fieldset" row>
            <Label for="radio" sm={2}>Envio*</Label>
            <Col sm={10}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" value="0" onChange={()=>this.updateFreightMode(0)} name="recoger" defaultChecked />{' '}
                  Recoger en nuestra tienda (Calle Gomera S/N Local 6, Fuengirola)
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" value="1" onChange={()=>this.updateFreightMode(1)} name="recoger" />{' '}
                  Envio a mi direccion
                </Label>
              </FormGroup>
            </Col>
          </FormGroup> 
          <FormGroup tag="fieldset" row>
            <Label for="radio" sm={2}>Fecha*</Label>
            <Col sm={10}>
                {datePicker} Mínimo 4 dias desde la fecha del pedido
            </Col>
          </FormGroup>                   
          <FormGroup row>
            <Label for="exampleText" sm={2}>Mensaje</Label>
            <Col sm={10}>
              <Input type="textarea" name="message" id="message" placeholder="Timbre no funciona" />
            </Col>
          </FormGroup> 
          <FormGroup>
            <p className="contacto-other">Tu pedido</p> 
          </FormGroup>
          <div className="row"> 
        <div className="col-9 cartHeaderName">Producto</div>
        <div className="col-3 cartHeader checkoutCosts">SubTotal</div>          
        <div className="clearfix"></div>  
      </div>
      {this.state.products}
      <div className="row"> 
        <div className="col-9 cartName bolder">Subtotal</div>
        <div className="col-3 checkoutCosts bolder">{Number(this.state.totalPrice/2).toFixed(2)}€</div>          
        <div className="clearfix"></div>  
      </div>      
      <div className="row"> 
        <div className="col-9 cartName bolder">Precio por transporte (Gratis por pedidos de 100€ o mas)*:</div>
        <div className="col-3 checkoutCosts bolder">{Number(this.state.freightPrice).toFixed(2)}€</div> 
      </div>
      <div className="row"> 
        <div className="col-9 cartName bolder">IVA</div>
        <div className="col-3 checkoutCosts bolder">{(Number(((this.state.absoluteTotalPrice)+(this.state.freightPrice))*0.2)).toFixed(2)}€</div> 
      </div>
      <div className="row"> 
        <div className="col-9 cartName bolder">Total</div>
        <div className="col-3 checkoutCosts bolder">{Number(this.state.absoluteTotalPrice+this.state.freightPrice).toFixed(2)}€</div> 
      </div>
      <div className="clearfix"></div>
          <FormGroup row>
            <Col sm={12} className="checkoutCosts">
              <div className="divCondiciones checkoutCosts">
                <Label check className="condiciones">
                <Input type="checkbox" onClick={()=>this.prepSabadellForm()} />&nbsp;&nbsp;&nbsp;<Link to="/compras" target="_blank" className="checkoutCosts">He leido y aceptado las politicas de venta</Link></Label>
              </div>
            </Col>
          </FormGroup>
          <FormGroup>
            <p className="contacto-other">Pago</p>
            <Col sm={12} className="checkoutCosts">
            Para poder seguir con el pago, debes de aceptar las politicas de venta. Luego seras dirigido al sistema de pago de Sabadell.<br/><br/>
            </Col>
          </FormGroup> 
        </Form>
        {formCode}

      <div className="checkoutDiv">
      <strong>*Transporte</strong>
         <ul>         
          <li>Tiempo de entrega: Tiempo de entrega puede variar.</li>
          <li>Precios: Envios nacionales {this.state.nationPrice}€. Gratis envio por pedidos de 100€ o mas.</li>
        </ul>
        <strong>*Cambios y devoluciones</strong><br/>
        Si por cualquier razón no queda satisfecho/a con su compra contacte por e-mail en info@vegabonty.com en un plazo máximo de 24 horas desde la recepción del pedido, indicándonos claramente cuál es el problema. Para cambios y devoluciones debe llevar su pedido y factura a la tienda Vegabonty y le daremos la solución que necesite. No aceptaremos cambios o devoluciones si previamente no hemos recibido notificación.
        <br/><br/>
        <strong>*Cancelaciones</strong><br/>
        El plazo máximo para cancelar un pedido es de 24 horas antes de la fecha de entrega.
        <br/><br/>

        <strong>VERSION BETA - Descargo de responsabilidad</strong><br/>
        Tenga en cuenta que esta es una versión beta del sitio web de Vegabonty que 
        aún se está sometiendo a pruebas finales antes de su lanzamiento oficial. Vegabonty no ofrece ninguna garantía,
        ya sea expresa o implícita, en cuanto a la idoneidad o usabilidad del
        sitio web o cualquiera de sus contenidos.
        Vegabonty no será responsable de ninguna pérdida, ya sea directa,
        indirecto, especial o consecuente, sufrido por cualquier parte como resultado
        de su uso del sitio web de Vegabonty. Si se carga una cantidad incorrecta debido a errores técnicos, le reembolsaremos y cancelaremos su pedido.
        Si encuentra algún error, fallo, falta de funcionalidad o
        otros problemas en el sitio web, háganoslo saber de inmediato para que podamos
        rectificarlos lo antes posible. ¡Su ayuda en este sentido es enormemente
        apreciado! Puede escribirnos a esta dirección info@vegabonty.com.        
        <br/><br/>
        <strong>BETA VERSION - Disclaimer</strong><br/>
        Please note that this is a beta version of the Vegabonty website which is
        still undergoing final testing before its official release. Vegabonty does not give any warranties,
        whether expressed or implied, as to the suitability or usability of the
        website or any of its content.
        Vegabonty will not be liable for any loss, whether such loss is direct,
        indirect, special or consequential, suffered by any party as a result
        of their use of the Vegabonty website. If a wrong amount is charged due to Technical errors we will refund and cancel your order.
        Should you encounter any bugs, glitches, lack of functionality or
        other problems on the website, please let us know immediately so we
        can rectify these accordingly. Your help in this regard is greatly
        appreciated! You can write to us at this address info@vegabonty.com.
      </div>      
    </div>
)
    }
};

export default Checkout;