import React from "react";
import "./home.css";
import CategoryListing from "./../categoryListing/categoryListing";



export class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            pagetitle: ""
        }
    }
    componentDidMount() {

        // Hämta fras
        fetch('https://vegabontycmsone.azurewebsites.net/phrases/1')
        .then(response => response.json())
        .then(phrase => {this.setState({pagetitle: phrase.Content});
        })
        
    } 
    
    render() {
        return(
        <div>           
            <div className="container">
                <div className="homeCategoryListing">                
                <CategoryListing></CategoryListing>
                </div>

                <div className="col-md-12 horarios">
                    <p className="apertura">Horario de apertura</p>
                    <div className="hours">                        
                            Lunes: 13.00-18.30 <br></br>
                            Martes: Cerrado <br></br>
                            Miercoles a domingo: 12:00-20:00 <br></br>
                    </div>
                </div>
            </div>       
            
                {/* <Carousel></Carousel>    */}
                <h1 className="phrase1">Reposteria Vegana</h1> 
                <h2 className="phraseText"> Somos una empresa 100% vegana con varios objetivos</h2>            
                <p className="empresa">Somos una empresa 100% vegana con varios objetivos;
                lo vegano no tiene por que ser caro, y por ser vegano, cualquier tarta o dulce que se pueda elaborar no deja de ser delicioso</p> 

                <h1 className="phrase1">¿Qué es vegano?</h1> 
                            
                <p className="empresa">Significa que todo en nuestro obrador es 100% vegetal. No contiene productos de animal como por ejemplo leche, miel, huevo, manteca de cerdo etc. 
Contienen gluten, frutos secos y otros ingredientes de origen vegetal.</p> 

                {/* <h1 className="phrase1">{this.state.pagetitle}</h1> 
                <div className="col-md-12 bild">
                <Form>                
                     <p className="suscribirse"></p>
                     <p className="suscribirseText linespacing2">Suscríbete para recibir noticias sobre promociones,            
                     ofertas y eventos.</p>
                         <FormGroup className="row">
                          <Label for="nombre" className="col-md-3 list"></Label>
                             <Input className="col-md-6 inputContact" placeholder="e-mail" type="nombre" name="nombre" id="examplNombre"/>
                        </FormGroup>
                <Button className="subscribir btn">Enviar</Button>              
                </Form>
                 </div> */}
                {/* <div className="clearfix"></div> */}
        </div>
        );
    };
}

export default Home;